$white: #ffffff;
$offWhite: #f4f4f4;
$transBorder: #00000029;

$primaryLabel: #43425d;
$linkActive: #4c4c4c;
$textColor: #9a9a9a;

$actionSelected: #af6121; // #f6892e;
$boxShadow: #0000000a;
$borderColor: #9a9a9a80;
$focusBorder: #9a9a9a;

$greenStatus:#4d8230; // #78cc4b; 
$actionLink: #317ca4; // #3e9fd1;

$errorColor: #f85959;
$graphRed: #c32e07;
$graphGreen: #a1ba64;
$graphGreen1: #6e9d3e;
$graphYellow: #eccc1f;
$inputTextColor: #4c4c4c;
$black: #000000;
