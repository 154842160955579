@import "../../styles/colors.scss";

.notification {
  position: fixed;
  top: 0;
  left: 20%;
  width: 60%;
  margin: 0 auto;
  margin-top: 15px;
  z-index: 2;

  .container {
    border-radius: 5px;
    padding: 15px 10px;
    font-weight: bold;
    position: relative;
    background: white;
    box-shadow: 0px 2px 5px #ccc;
    .button {
      cursor: pointer;
      position: absolute;
      right: -10px;
      top: -10px;
      background: #fff;
      border-radius: 50%;
      height: 24px;
      width: 24px;
    }
  }

  .success {
    border: 1px solid $greenStatus;
    color: $greenStatus;
    .successClose {
      color: $greenStatus;
      border: 1px solid $greenStatus;
    }
  }

  .error {
    border: 1px solid $errorColor;
    color: $errorColor;
    .errorClose {
      color: $errorColor;
      border: 1px solid $errorColor;
    }

    .errorMessage {
      list-style: none;
      margin: 0;
      padding: 0;

      .errorList {
        font-weight: normal;
      }
    }
  }
}
